// eslint-disable-next-line no-restricted-imports
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { ReactNode, useCallback } from "react";
import { Snackbar } from "../components/Snackbar";

export type Notification = {
  type?: "default" | "error" | "warning";
  message: ReactNode | string;
  dismissible?: boolean;
  onView?: () => void;
  onUndo?: () => Promise<void> | void;
};

export type UseNotificationsReturnType = {
  send: (notification: Notification, options?: OptionsObject) => SnackbarKey;
};

export const useNotifications = (): UseNotificationsReturnType => {
  const { enqueueSnackbar } = useSnackbar();

  const send = useCallback(
    (notification: Notification, options?: OptionsObject) => {
      const key = enqueueSnackbar("", {
        content: (key) => <Snackbar id={key} {...notification} />,
        ...options,
      });

      return key;
    },
    [enqueueSnackbar]
  );

  return { send };
};
