import { Box, Button, darken, IconButton, lighten, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
// eslint-disable-next-line no-restricted-imports
import { SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback } from "react";
import { Notification } from "../hooks/useNotifications";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 4,
      maxWidth: 420,
      minWidth: 100,
      paddingBottom: theme.spacing(1),
    },
    close: {
      position: "absolute",
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    closeIcon: {
      width: 22,
      height: 22,
    },
    title: {
      padding: theme.spacing(1.5, 3, .5, 2),
      ...theme.typography.body2,
    },
    dismissTitlePadding: {
      paddingRight: theme.spacing(6),
    },
    defaultColors: {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
    errorColors: {
      backgroundColor: lighten(theme.palette.error.light, 0.8),
      color: theme.palette.error.dark,
    },
    warningColors: {
      backgroundColor: lighten(theme.palette.warning.light, 0.7),
      color: darken(theme.palette.warning.dark, 0.3),
    },
    actionsWrap: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
    },
    actionBtn: {},
  }),
  {
    classNamePrefix: "Snackbar",
  }
);

export type SnackbarJSSClassKey = keyof ReturnType<typeof useStyles>;

export type SnackbarProps = Notification & {
  classes?: Partial<ClassNameMap<SnackbarJSSClassKey>>;
  className?: string;
  id: number | string;
};

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ className, classes: extClasses, id, type = "default", message, dismissible, onUndo, onView }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const { closeSnackbar } = useSnackbar();

    const colorClassMap = {
      [classes.defaultColors]: type === "default",
      [classes.errorColors]: type === "error",
      [classes.warningColors]: type === "warning",
    };

    const handleClose = useCallback(() => closeSnackbar(id), [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className={clsx(classes.root, className, colorClassMap)}>
        {!!dismissible && (
          <IconButton aria-label="close" className={classes.close} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
        <Box className={clsx(classes.title, { [classes.dismissTitlePadding]: !!dismissible })}>{message}</Box>
        <Box className={classes.actionsWrap}>
          {!!onUndo && (
            <Button className={clsx(classes.actionBtn, colorClassMap)} onClick={onUndo} size="small" color="primary">
              Undo
            </Button>
          )}
          {!!onView && (
            <Button className={clsx(classes.actionBtn, colorClassMap)} onClick={onUndo} size="small" color="primary">
              View
            </Button>
          )}
        </Box>
      </SnackbarContent>
    );
  }
);
